const env = import.meta.env;

export default {
  api: {
    baseUrl: env.VITE_API_BASE_URL
  },
  plaid: {
    environment: env.VITE_PLAID_ENV
  },
  google: {
    recaptchaSiteKey: env.VITE_RECAPTCHA_SITE_KEY,
    googleTagId: env.VITE_GTAG_ID
  },
  full_story: {
    org_id: env.VITE_FS_ORG_ID
  },
  other: {
    accelerateTaxClientId: env.VITE_ACCELERATE_TAX_CLIENT_ID,
    lmnClientId: env.VITE_LMN_CLIENT_ID,
    ignisClientId: env.VITE_IGNIS_CLIENT_ID
  },
  env: env.MODE,
  widgetUrl: env.VITE_WIDGET_URL,
  sentryRelease: env.VITE_SENTRY_RELEASE,
  sentryDist: env.VITE_APP_ENV,
  oktaRequiredForOktaUsers: env.VITE_OKTA_REQUIRED_FOR_USERS,
  smartyStreetsKey: env.VITE_SMARTY_STREETS_KEY,
  includeMocks: env.VITE_INCLUDE_MOCKED_FEATURES === "true",
  sentryDashboardDsn: env.VITE_SENTRY_DSN,
  amplitudeAnalyticsKey: env.VITE_AMPLITUDE_KEY,
  ws: {
    broadcaster: env.VITE_WS_BROADCASTER || "pusher",
    key: env.VITE_WS_KEY,
    host: env.VITE_WS_HOST || "realtime-pusher.ably.io",
    port: env.VITE_WS_PORT || 443,
    cluster: env.VITE_WS_CLUSTER || "mt1"
  }
};
