import { computed, reactive, readonly, watchEffect } from "vue";
import { useStore } from "vuex";
import {
  LENDFLOW_ROLE_GROUP,
  CLIENT_ROLE_GROUP,
  FUNDING_ADVISOR,
  SUPER_ADMIN,
  ADMIN,
  UNDERWRITER,
  CLIENT_ADMIN,
  CLIENT_UNDERWRITER,
  USER,
  FUNDER_ADMIN,
  FUNDER_USER,
  ANALYST,
  CLIENT_ANALYST,
  CLIENT_FUNDING_ADVISOR
} from "@/helpers/constants";
import throttle from "lodash/throttle";
import { useRoute } from "vue-router";
import type { IRootState } from "@/models/state";
import type { IGetAuthRoutes } from "@/models/common";
import type { IUser } from "@/models/users";
import type { IClient } from "@/models/clients";
import type {
  AbilityArguments,
  IAbility,
  IIntegration
} from "@/models/authentications";
import { getAbilityChecksum } from "@/helpers/auth";
import { useI18n } from "vue-i18n";
import type { PermissionSubject, Ability } from "@/enums/auth";
import config from "@/config";

export const useAuth = () => {
  const { getters } = useStore();

  const user = computed<IUser | null>(() => getters["auth/user"]);
  const accessToken = computed<string>(() => getters["auth/accessToken"]);

  const authCheck = computed(() => {
    const roles = user.value?.roles || [];

    return {
      isLendflowUser: roles.some((role) => LENDFLOW_ROLE_GROUP.includes(role)),
      isClient: roles.some((role) => CLIENT_ROLE_GROUP.includes(role)),
      isClientUser: roles.includes(USER),
      isClientAdmin: roles.includes(CLIENT_ADMIN),
      isClientUnderwriter: roles.includes(CLIENT_UNDERWRITER),
      isFundingAdvisor: roles.includes(FUNDING_ADVISOR),
      isUnderwriter: roles.includes(UNDERWRITER),
      isSuperAdmin: roles.includes(SUPER_ADMIN),
      isAdmin: roles.includes(ADMIN) || roles.includes(SUPER_ADMIN),
      isSolelyAdmin: roles.includes(ADMIN) && roles.length === 1,
      isEmployee: roles.some((role) =>
        [UNDERWRITER, FUNDING_ADVISOR].includes(role)
      ),
      isLoggedIn: !!accessToken.value,
      isFunderAdmin: roles.includes(FUNDER_ADMIN),
      isFunderUser: roles.includes(FUNDER_USER),
      isFunder: roles.some((role) =>
        [FUNDER_ADMIN, FUNDER_USER].includes(role)
      ),
      isAnalyst: roles.includes(ANALYST),
      isClientAnalyst: roles.includes(CLIENT_ANALYST),
      isClientFundingAdvisor: roles.includes(CLIENT_FUNDING_ADVISOR),
      roles: roles || [],
      //TODO: remove once a more permanent solution is in place
      isAccelerateTaxClient:
        user.value?.client_id === config.other.accelerateTaxClientId,
      isAccelerateTaxViewer:
        user.value?.client_id === config.other.accelerateTaxClientId &&
        roles.includes(USER),
      isLmnClient: user.value?.client_id === config.other.lmnClientId,
      isIgnisClient: user.value?.client_id === config.other.ignisClientId
    };
  });

  const result = reactive({
    ...authCheck.value,
    currentUser: user.value
  });

  // todo: in LF-9998 a proper refactor of reactivity is going to take place
  watchEffect(() => {
    Object.assign(result, {
      ...authCheck.value,
      currentUser: user.value
    });
  });

  return readonly(result);
};

export const useDataCollectionCanEdit = () => {
  // Section "Data Collection" row "Edit/Delete Information"
  // https://lendflow.atlassian.net/wiki/spaces/LP/pages/1963524104/Workflow+Live+Permissions+Defined+-+when+both+LENDFLOW+AND+THE+CLIENT+control+the+stage
  const { isClientUser, isClientAnalyst } = useAuth();
  return !isClientUser && !isClientAnalyst;
};

export const useRefreshToken = (minutesInterval: number): void => {
  const store = useStore<IRootState>();
  let inactive = false;
  const accessToken = computed(() => store.state.auth.accessToken);

  //refresh token if user is active
  const getRefreshToken = () => {
    let refreshTokenTimeId: ReturnType<typeof setInterval> | null = null;
    let userActivityTimer: ReturnType<typeof setTimeout>;

    if (refreshTokenTimeId) {
      clearInterval(refreshTokenTimeId);
    }

    document.addEventListener(
      "mousemove",
      throttle(resetUserInactivityTimer, 1000)
    );
    document.addEventListener(
      "keydown",
      throttle(resetUserInactivityTimer, 1000)
    );
    document.addEventListener(
      "scroll",
      throttle(resetUserInactivityTimer, 1000),
      true
    );

    const duration = minutesInterval * 60 * 1000;

    refreshTokenTimeId = setInterval(() => {
      if (!inactive && accessToken.value) {
        store.dispatch("auth/refreshToken");
      }
    }, duration);

    function resetUserInactivityTimer() {
      inactive = false;
      clearTimeout(userActivityTimer);
      userActivityTimer = setTimeout(() => {
        inactive = true;
      }, duration);
      // 1000 milliseconds = 1 second
    }
  };
  getRefreshToken();
};

export const useGetAuthRoutes = (): IGetAuthRoutes => {
  const route = useRoute();

  const isFundingView = computed(() =>
    route.fullPath.includes("funding-partners")
  );

  return {
    loginPath: isFundingView.value ? "/funding-partners/login" : "/login",
    registerPath: isFundingView.value
      ? "/funding-partners/register"
      : "/register",
    forgotPasswordPath: isFundingView.value
      ? "/funding-partners/forgot-password"
      : "/forgot-password",
    isFundingView
  };
};

export const useIntegration = () => {
  const { getters } = useStore();
  const { t } = useI18n();

  const user = computed<IUser>(() => getters["auth/user"]);
  const activeClient = computed<IClient>(
    () => getters["auth/authClientSettings"]
  );
  const integration = computed<IIntegration>(
    () => getters["auth/authClientIntegration"]
  );
  const widgets = computed<Record<string, string>>(
    () => getters["options/workflowTemplatesWidgets"]
  );
  const userId = computed(() => user.value?.client_id as string | null);
  const widgetToken = computed<string>(() => integration.value?.widget_token);
  const accessToken = computed<string>(() => integration.value?.access_token);
  const authClientProductTypes = computed<Record<string, string>>(
    () => integration.value?.product_types
  );

  const getFilteredProductTypes = ({
    productsToExclude = [],
    includeNone = true
  }: {
    productsToExclude?: Array<string | number>;
    includeNone?: boolean;
  }) => {
    let productTypes = authClientProductTypes.value;

    if (userId.value && authClientProductTypes.value) {
      if (activeClient.value?.equipment_rental_enabled) {
        productTypes = {
          ...productTypes,
          7: t("WORKFLOW.PRODUCT_TYPES.EQUIPMENT_RENTAL")
        };
      }

      if (activeClient.value.name === "Bevel") {
        productTypes = {
          ...productTypes,
          12: t("WORKFLOW.PRODUCT_TYPES.BEVEL_CUSTOM_FUNDING"),
          13: t("WORKFLOW.PRODUCT_TYPES.LEAD_CAPTURE")
        };
      }
      productTypes = {
        ...productTypes,
        14: t("WORKFLOW.PRODUCT_TYPES.INVOICE_FACTORING_RAILZ"),
        18: t(
          "WORKFLOW.PRODUCT_TYPES.LENDING_WIDGET_WITHOUT_SIGNED_APP_AND_BANKS"
        ),
        19: t("WORKFLOW.PRODUCT_TYPES.LENDING_WIDGET_WITHOUT_SIGNED_APP"),
        22: t("WORKFLOW.PRODUCT_TYPES.INVOICE_FACTORING_CODAT"),
        23: t("WORKFLOW.PRODUCT_TYPES.ERC_WIDGET"),
        24: t("WORKFLOW.PRODUCT_TYPES.INVOICE_FACTORING"),
        29: t("WORKFLOW.PRODUCT_TYPES.ERC_WIDGET_LONG")
      };

      if (includeNone) {
        productTypes["none"] = t("COMMON.NONE");
      }
    } else {
      productTypes = { ...productTypes, ...widgets.value };
    }

    if (productsToExclude?.length) {
      productsToExclude.forEach((productKey) => {
        if (productTypes[productKey]) {
          delete productTypes[productKey];
        }
      });
    }

    return productTypes;
  };

  return {
    widgetToken,
    accessToken,
    integration,
    getFilteredProductTypes
  };
};

export const usePermissions = () => {
  const { getters, dispatch, commit } = useStore();
  const { t } = useI18n();

  const EXCLUDE_ORG_TITLE_TABS = [
    t("PRESETS.TITLE"),
    t("ORGANIZATION.USER_MANAGEMENT")
  ];

  const generatePermissionsRequest = (
    subject: PermissionSubject,
    ability: Ability,
    argumentsList: AbilityArguments
  ) => {
    const permissionsRequest: IAbility = {
      subject,
      ability
    };
    if (argumentsList) {
      permissionsRequest.arguments = argumentsList;
      const indexOfUnknown = Object.values(argumentsList).findIndex(
        (val) => val === undefined || val === null
      );
      if (indexOfUnknown !== -1) {
        permissionsRequest.malformed = true;
        permissionsRequest.allowed = false;
      }
    }
    return permissionsRequest;
  };

  const getPermissionAbility = (abilities: IAbility[]): Promise<IAbility[]> =>
    dispatch("auth/getPermissionAbility", abilities);

  const currentPermissions = computed<Record<string, IAbility>>(
    () => getters["auth/permissionsList"]
  );

  const addPermissionsToRequest = (abilities: IAbility[]) => {
    commit("auth/addPermissionToRequest", abilities);
  };

  const checkPermissions = async (
    subject: PermissionSubject,
    ability: Ability,
    argumentsList: AbilityArguments
  ): Promise<boolean> => {
    const permissionAbility = generatePermissionsRequest(
      subject,
      ability,
      argumentsList
    );
    const checksum = getAbilityChecksum(permissionAbility);
    if (!permissionAbility.malformed) {
      if (!currentPermissions.value[checksum]) {
        addPermissionsToRequest([permissionAbility]);
        commit("auth/setPermissionsLoading", true);
      }
      await dispatch("auth/getPermissionAbility", [permissionAbility]);
    } else {
      commit("auth/addPermission", [permissionAbility]);
    }
    return currentPermissions.value[checksum]?.allowed ?? false;
  };

  const canPerformActionReactive = (
    subject: PermissionSubject,
    ability: Ability,
    argumentsList: AbilityArguments = {}
  ) => {
    const permissionAbility = generatePermissionsRequest(
      subject,
      ability,
      argumentsList
    );
    const checksum = getAbilityChecksum(permissionAbility);
    if (!permissionAbility.malformed) {
      if (!currentPermissions.value[checksum]) {
        addPermissionsToRequest([permissionAbility]);
        commit("auth/setPermissionsLoading", true);
      }
      dispatch("auth/checkAuthBatched");
    } else {
      commit("auth/addPermission", [permissionAbility]);
    }
    return computed(() => {
      const currentPermission = currentPermissions.value[checksum];
      return !!currentPermission?.allowed;
    });
  };

  return {
    generatePermissionsRequest,
    getPermissionAbility,
    canPerformActionReactive,
    addPermissionsToRequest,
    checkPermissions,
    EXCLUDE_ORG_TITLE_TABS
  };
};
