import type { LogEndpointType } from "@/enums/communicationLogs";
import { formatString } from "@/helpers/formatting";
import type { IApplication } from "@/models/applications";
import type {
  CallOutPayload,
  CommunicationLogResponse,
  CommunicationFilters,
  Task,
  CallNotePayload,
  CallLogData,
  CallLog,
  TaskLogData,
  CommunicationLogBase,
  CommunicationLogComment,
  CreateTaskPayload,
  SmsLogData
} from "@/models/communicationLogs";
import type { DealTelephone } from "@/models/users";
import type { ActivityHubOptions } from "@/models/workflows";
import { axiosClient } from "@/services/client";
import endpoints from "@/services/endpoints";

class CommunicationService {
  async callOut(
    applicationId: IApplication["id"],
    payload: CallOutPayload
  ): Promise<CallLog> {
    const response = await axiosClient.post(
      formatString(endpoints.COMMUNICATION.CALL_OUT, applicationId),
      payload
    );
    return response.data;
  }

  async getCommunicationList(params: Partial<CommunicationFilters> = {}) {
    const response = await axiosClient.get<CommunicationLogResponse>(
      endpoints.COMMUNICATION.LOGS.ALL_INDEXED,
      {
        params
      }
    );
    return response.data;
  }

  async getCommunicationLog(logId: CommunicationLogBase["id"]) {
    const url = formatString(endpoints.COMMUNICATION.LOGS.SINGLE, logId);
    const response = await axiosClient.get<CommunicationLogBase>(url);
    return response.data;
  }

  async createTask(payload: CreateTaskPayload) {
    const response = await axiosClient.post<{ data: Task }>(
      formatString(endpoints.COMMUNICATION.TASKS.CREATE),
      payload
    );
    return response.data.data;
  }

  async updateTask(taskId: Task["id"], payload: Partial<TaskLogData>) {
    const response = await axiosClient.patch<{ data: Task }>(
      formatString(endpoints.COMMUNICATION.TASKS.UPDATE, taskId),
      payload
    );
    return response.data.data;
  }

  async updateCallNote(logId: CallLogData["id"], payload: CallNotePayload) {
    const response = await axiosClient.put<{ data: CallLog }>(
      formatString(endpoints.COMMUNICATION.CALL_LOGS.UPDATE, logId),
      payload
    );
    return response.data.data;
  }

  async deleteTask(taskId: Task["id"]) {
    const response = await axiosClient.delete(
      formatString(endpoints.COMMUNICATION.TASKS.UPDATE, taskId)
    );
    return response.data.data;
  }

  async createComment(
    type: LogEndpointType,
    logId: CommunicationLogBase["id"],
    comment: string
  ) {
    const endpoint = formatString(
      endpoints.COMMUNICATION.COMMENTS.ALL,
      type,
      logId
    );
    const response = await axiosClient.post<{ data: CommunicationLogComment }>(
      endpoint,
      { comment }
    );
    return response.data.data;
  }

  async updateComment(
    type: LogEndpointType,
    logId: CommunicationLogBase["id"],
    commentId: CommunicationLogComment["id"],
    comment: string
  ) {
    const endpoint = formatString(
      endpoints.COMMUNICATION.COMMENTS.SINGLE,
      type,
      logId,
      commentId
    );
    const response = await axiosClient.put<{ data: CommunicationLogComment }>(
      endpoint,
      { comment }
    );
    return response.data.data;
  }

  async deleteComment(
    type: LogEndpointType,
    logId: CommunicationLogBase["id"],
    commentId: CommunicationLogComment["id"]
  ) {
    const endpoint = formatString(
      endpoints.COMMUNICATION.COMMENTS.SINGLE,
      type,
      logId,
      commentId
    );
    return await axiosClient.delete(endpoint);
  }

  async blockPhoneNumber(payload: {
    applicationId: string;
    phone: Omit<DealTelephone, "is_primary"> & { name: string };
  }) {
    const response = await axiosClient.post(
      formatString(endpoints.COMMUNICATION.PHONES.BLOCK, payload.applicationId),
      payload.phone
    );
    return response.data;
  }

  async unblockPhoneNumber(payload: {
    applicationId: string;
    phone: {
      number: DealTelephone["number"];
    };
  }) {
    const response = await axiosClient.put(
      formatString(
        endpoints.COMMUNICATION.PHONES.UNBLOCK,
        payload.applicationId
      ),
      payload.phone
    );
    return response.data;
  }

  async getActivityHubInfo(applicationId?: IApplication["id"]) {
    const request = applicationId
      ? formatString(
          endpoints.GET_OPTIONS.ACTIVITY_HUB.TABS.DEAL,
          applicationId
        )
      : endpoints.GET_OPTIONS.ACTIVITY_HUB.TABS.ALL;
    const response = await axiosClient.get<{ data: ActivityHubOptions }>(
      request
    );
    return response.data;
  }

  async assignSmsToApplication(application_id: string, sms_id: number) {
    const response = await axiosClient.put<{ data: SmsLogData }>(
      formatString(endpoints.COMMUNICATION.SMS.ASSIGN_TO_APPLICATION, sms_id),
      {
        application_id
      }
    );
    return response.data;
  }
}

const communicationService = new CommunicationService();
export default communicationService;
