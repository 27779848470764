import { v4 as uuid } from "uuid";
import type { Component } from "vue";
import IconSuccessRoundBig from "@/components/icons/IconSuccessRoundBig.vue";
import IconExclamationMarkRound from "@/components/icons/IconExclamationMarkRound.vue";
import IconDelete from "@/components/icons/IconDelete.vue";
import IconFail from "@/components/icons/IconFail.vue";
import IconCopy from "@/components/icons/IconCopy.vue";
import IconLoading from "@/components/icons/IconLoadingCircle.vue";

export type ToastType =
  | "success"
  | "warning"
  | "put"
  | "post"
  | "patch"
  | "delete"
  | "error"
  | "copy"
  | "loading";

export interface ToastAction {
  label: string;
  handler: () => Promise<void> | void;
}

export type ToastMessageId = ReturnType<typeof uuid>;

export interface ToastMessageData {
  title: string;
  subTitle?: string;
  type: ToastType;
  action?: ToastAction;
  canClose?: boolean;
  autoClose?: boolean;
  duration?: number;
}

export const TOAST_DEFAULT_DURATION = 5_000 as const;

export const TOAST_ICONS: Record<ToastType, Component> = {
  success: IconSuccessRoundBig,
  warning: IconExclamationMarkRound,
  put: IconSuccessRoundBig,
  post: IconSuccessRoundBig,
  patch: IconSuccessRoundBig,
  delete: IconDelete,
  error: IconFail,
  copy: IconCopy,
  loading: IconLoading
} as const;

export class ToastMessage {
  toastInstance: Toast;
  id: ToastMessageId;
  title: string;
  subTitle?: string;
  type: ToastType;
  canClose: boolean;
  autoClose: boolean;
  duration: number;
  action?: ToastAction;
  icon: (typeof TOAST_ICONS)[keyof typeof TOAST_ICONS];

  constructor(data: ToastMessageData & { toastInstance: Toast }) {
    this.id = uuid();
    this.toastInstance = data.toastInstance;
    this.title = data.title;
    this.subTitle = data.subTitle || "";
    this.type = data.type;
    this.canClose = data.canClose ?? true;
    this.autoClose = data.autoClose ?? true;
    this.duration = data.duration || TOAST_DEFAULT_DURATION;
    this.icon = TOAST_ICONS[data.type];
    this.action = data.action;

    this.postInit();
  }

  private postInit() {
    if (this.canClose && this.autoClose) {
      setTimeout(() => this.destroy(), this.duration);
    }
  }

  destroy() {
    return this.toastInstance.removeMessage(this.id);
  }
}

export class Toast {
  messages: Array<ToastMessage> = [];

  constructor() {
    //
  }

  addMessage(data: ToastMessageData) {
    if (this.messages.some((msg) => msg.title === data.title)) {
      return undefined;
    }
    const newMessage = new ToastMessage({ ...data, toastInstance: this });
    this.messages.push(newMessage);
    return newMessage;
  }

  removeMessage(id: ToastMessageId) {
    this.messages = this.messages.filter((msg) => msg.id !== id);
  }

  removeAllMessages() {
    this.messages = [];
  }

  removeAllMessagesExcept(type: ToastType) {
    this.messages = this.messages.filter((message) => message.type === type);
  }
}
